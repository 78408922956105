/* eslint-disable @typescript-eslint/no-use-before-define */
import styled from 'styled-components';
import { Typography } from 'antd';

import { METAMASK_FLASK_HOME } from '../env';
import { white } from '../config/colors';

function Description() {
  return (
    <Container>
      <Typography.Title level={4}>
        🔥 Supercharge your MetaMask with 0xTeabag! 🔥
      </Typography.Title>
      <Typography.Paragraph>
        With the power of{' '}
        <a href={METAMASK_FLASK_HOME} target="_blank" rel="noreferrer">
          MetaMask Snaps
        </a>{' '}
        0xTeabag can show your address labels inside MetaMask
        <br />
        so you never have to worry if you're sending your assets to the correct
        address again!
      </Typography.Paragraph>
      <Typography.Paragraph className="steps">
        Just complete these simple steps to get started:
      </Typography.Paragraph>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 24px;

  h4 {
    color: ${white};
    margin-bottom: 32px;
  }

  .steps {
    margin-top: 24px;
    color: ${white};
  }
`;

export default Description;
