/* eslint-disable @typescript-eslint/no-use-before-define */
import styled from 'styled-components';

import { Description } from '../components';
import { ConnectSnapSteps } from '../containers';

function Index() {
  return (
    <Container>
      <Description />
      <ConnectSnapSteps />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: 48px;
  margin-bottom: 100px;
`;

export default Index;
